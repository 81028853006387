import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
$("#nav1").addClass("active")
var interleaveOffset = 0.5; //视差比值
        var swiperOptions = {
            loop: true,
            speed: 1000,
            grabCursor: true,
            watchSlidesProgress: true,
            mousewheelControl: true,
            keyboardControl: true,
            autoplay: {
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: "#banner-swiper .swiper-button-next",
                prevEl: "#banner-swiper .swiper-button-prev",
              },
            on: {
                progress: function (swiper) {
                    for (var i = 0; i < swiper.slides.length; i++) {
                        var slideProgress = swiper.slides[i].progress;
                        var innerOffset = swiper.width * interleaveOffset;
                        var innerTranslate = slideProgress * innerOffset;
                        swiper.slides[i].querySelector("#banner-swiper .swiper-slide img").style.transform =
                            "translate3d(" + innerTranslate + "px, 0, 0)";
                    }
                },
                touchStart: function (swiper) {
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = "";
                    }
                },
                setTransition: function (swiper, speed) {
                    for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].style.transition = speed + "ms";
                        swiper.slides[i].querySelector("#banner-swiper .swiper-slide img").style.transition =
                            speed + "ms";
                    }
                },
            },
           
        };
        var swiper = new Swiper("#banner-swiper", swiperOptions);
        let bannerNext = $("#banner-swiper .swiper-button-next") 
        let bannerPrev = $("#banner-swiper .swiper-button-prev") 
        bannerNext.click(function () {
                bannerNext.addClass("active")
                bannerPrev.removeClass("active")          
        })
        bannerPrev.click(function () {
            bannerPrev.addClass("active")
            bannerNext.removeClass("active")    
        })

// 产品轮播        
var cpd1 = new Swiper('#product1 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product1 .swiper-button-next",
        prevEl: "#product1 .swiper-button-prev",
      },
});
var cpx1 = new Swiper('#product1 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product1 .swiper-button-next",
        prevEl: "#product1 .swiper-button-prev",
      },
});

var cpd2 = new Swiper('#product2 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product2 .swiper-button-next",
        prevEl: "#product2 .swiper-button-prev",
      },
});
var cpx2 = new Swiper('#product2 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product2 .swiper-button-next",
        prevEl: "#product2 .swiper-button-prev",
      },
});

var cpd3 = new Swiper('#product3 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product3 .swiper-button-next",
        prevEl: "#product3 .swiper-button-prev",
      },
});
var cpx3 = new Swiper('#product3 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product3 .swiper-button-next",
        prevEl: "#product3 .swiper-button-prev",
      },
});

var cpd4 = new Swiper('#product4 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product4 .swiper-button-next",
        prevEl: "#product4 .swiper-button-prev",
      },
});
var cpx4 = new Swiper('#product4 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product4 .swiper-button-next",
        prevEl: "#product4 .swiper-button-prev",
      },
});

var cpd5 = new Swiper('#product5 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product5 .swiper-button-next",
        prevEl: "#product5 .swiper-button-prev",
      },
});
var cpx5 = new Swiper('#product5 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product5 .swiper-button-next",
        prevEl: "#product5 .swiper-button-prev",
      },
});

var cpd6 = new Swiper('#product6 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product6 .swiper-button-next",
        prevEl: "#product6 .swiper-button-prev",
      },
});
var cpx6 = new Swiper('#product6 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product6 .swiper-button-next",
        prevEl: "#product6 .swiper-button-prev",
      },
});

var cpd7 = new Swiper('#product7 #pro1', {
    // effect: 'fade',
    loop: true,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product7 .swiper-button-next",
        prevEl: "#product7 .swiper-button-prev",
      },
});
var cpx7 = new Swiper('#product7 #pro2', {
    // effect: 'fade',
    loop: true,
    initialSlide: 1,
    observer: true, //修改swiper自己或子元素时，自动初始化swiper，主要是这两行
    observeParents: true, //修改swiper的父元素时，自动初始化swiper
    navigation: {
        nextEl: "#product7 .swiper-button-next",
        prevEl: "#product7 .swiper-button-prev",
      },
});

let proNext = $(".pro-swiper .swiper-button-next") 
let proPrev = $(".pro-swiper .swiper-button-prev") 
proNext.click(function () {
    proNext.addClass("active")
    proPrev.removeClass("active")          
})
proPrev.click(function () {
    proPrev.addClass("active")
    proNext.removeClass("active")    
})

$(".pro-nav a").click(function () {
    let index = $(this).index();
    $(this).addClass("active").siblings().removeClass("active");
    $(".pro-lunbo .pro-swiper").eq(index).addClass("active").siblings().removeClass("active");
})
$(".pro-nav a:first").addClass("active");
$(".pro-lunbo .pro-swiper:first").addClass("active");